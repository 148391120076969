// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBq4UpYg5k17fwzg7FOkJXng5_JMHy9Yl4",
  authDomain: "zene-bioscience-642b8.firebaseapp.com",
  projectId: "zene-bioscience-642b8",
  storageBucket: "zene-bioscience-642b8.appspot.com",
  messagingSenderId: "434802922483",
  appId: "1:434802922483:web:6d35d7b64ee060f85ef886"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getFirestore(app);

export { database };
