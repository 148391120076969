import {Container} from "react-bootstrap";
import Confetti from 'react-confetti';
import {Fragment, MouseEvent, useEffect, useState} from 'react';
import {collection, query, where, getDocs, addDoc} from 'firebase/firestore';


import {database} from '../../firebaseConfig';
import {ResponseType} from "../../utils/enums";
import {emailValidator, nameValidator} from "../../utils/validator";
import ArrowRightSVG from "../../assets/images/svgs/arrow-right-primary.svg";
import ArrowRightDisabledSVG from "../../assets/images/svgs/arrow-right-primary-disabled.svg";

type TDimension = {
  width: undefined | number,
  height: undefined | number
};

const Newsletter = () => {
  const [loading, setLoading] = useState(false);
  const subscribersCollection = collection(database, "subscribers");
  const [responseMessage, setResponseMessage] = useState<null | string>(null);
  const [name, setName] = useState({value: "", errorState: false});
  const [email, setEmail] = useState({value: "", errorState: false});
  const colors = ['#f44336', '#9c27b0', '#00628E', '#03a9f4', '#00bcd4', '#4CAF50', '#CDDC39', '#FFEB3B', '#FFC107', '#FFAB00'];
  const [windowDimension, setWindowDimension] = useState<TDimension>({
    width: undefined,
    height: undefined
  });

  const isInvalid = !name.value || name.errorState || !nameValidator(name.value)
    || !email.value || email.errorState || !emailValidator(email.value);

  useEffect(() => {
    if (responseMessage === ResponseType.EXISTS || responseMessage === ResponseType.SUCCESS) {
      window.addEventListener('scroll', handleResize);
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('scroll', handleResize);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [responseMessage]);

  const resetResponseType = () => {
    setTimeout(() => setResponseMessage(null), 10000)
  };

  const handleResize = () => {
    setWindowDimension({
      width: Math.max(
        window.innerWidth + window.scrollX,
        document.documentElement.scrollWidth
      ),
      height: Math.max(
        window.innerHeight + window.scrollY,
        document.documentElement.scrollHeight
      )
    });
  };

  const resetForm = () => {
    setLoading(false);
    setName({value: "", errorState: false});
    setEmail({value: "", errorState: false});
    resetResponseType();
  };

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    try {
      if (isInvalid) {
        if (!nameValidator(name.value) || !name.value) setName(prevState => ({...prevState, errorState: true}));
        if (!emailValidator(email.value) || !email.value) setEmail(prevState => ({...prevState, errorState: true}));
      } else {
        setLoading(true);
        const queryData = query(subscribersCollection, where('email', '==', email.value));
        const querySnapshot = await getDocs(queryData);

        if (!querySnapshot.empty) setResponseMessage(ResponseType.EXISTS);
        else {
          await addDoc(subscribersCollection, {
            name: name.value,
            email: email.value
          });
          setResponseMessage(ResponseType.SUCCESS);
        }
        resetForm();
      }
    } catch (error) {
      setLoading(false);
      setResponseMessage(ResponseType.ERROR);
      resetResponseType()
    }
  }

  return (
    <section className="bg-primary py-5 px-3 px-lg-0">
      <Container>
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center gap-3 gap-xxl-5">
          <h3 className="text-white text-center text-md-start flex-grow-1">Sign up for our newsletter</h3>
          <div className="d-flex flex-column flex-md-row flex-fill w-100 gap-4">
            <div className={`form-floating flex-fill ${name && name.errorState && "is-invalid"}`}>
              <input type="text" inputMode="text" id="name"
                     placeholder="name" className="form-control" value={name.value}
                     onChange={event => !nameValidator(event.target.value)
                       ? setName({value: event.target.value, errorState: true})
                       : setName({value: event.target.value, errorState: false})}/>
              <label htmlFor="name">Name</label>
            </div>
            <div className={`form-floating flex-fill ${email && email.errorState && "is-invalid"}`}>
              <input type="email" inputMode="email" id="email"
                     placeholder="email" className="form-control" value={email.value}
                     onChange={event => !emailValidator(event.target.value)
                       ? setEmail({value: event.target.value.toLowerCase(), errorState: true})
                       : setEmail({value: event.target.value.toLowerCase(), errorState: false})}/>
              <label htmlFor="email">Email Address</label>
            </div>
          </div>
          <button className="btn btn-white mx-auto mx-md-0" disabled={loading || name.errorState || email.errorState}
                  onClick={event => handleSubmit(event)}>
            Submit
            {loading ?
              <Fragment>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                <span className="visually-hidden"/>
              </Fragment>
              : <Fragment>
                {(loading || name.errorState || email.errorState)
                  ? <img src={ArrowRightDisabledSVG} alt="arrow" loading="lazy"/>
                  : <img src={ArrowRightSVG} alt="arrow" loading="lazy"/>
                }
              </Fragment>
            }
          </button>
        </div>

        {(responseMessage) &&
					<div className="text-center mt-5">
            {(responseMessage === ResponseType.SUCCESS || responseMessage === ResponseType.EXISTS)
              ? <Fragment>
                <Confetti colors={colors} wind={0.01} gravity={0.095}
                          width={windowDimension.width} height={windowDimension.height}/>
                <h5 className="text-white">&#127881;&nbsp;
                  {(responseMessage === ResponseType.SUCCESS) ? "Thank you for subscribing!" : "You are a subscriber, thanks for staying!"}
                  &nbsp;&#127881;
                </h5>
              </Fragment>
              : <h6 className="text-danger">&#9888;An error occurred. Please try again.&#128542;</h6>
            }
					</div>
        }
      </Container>
    </section>
  );
}

export default Newsletter;
