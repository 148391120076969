import Drug from "../../assets/images/jpegs/drug.jpeg";
import Consult from "../../assets/images/pngs/consult.png";
import Training from "../../assets/images/jpegs/training.jpeg";
import Biomedical from "../../assets/images/jpegs/biomedical.jpeg";
import BioService from "../../assets/images/jpegs/bio-service.jpeg";

export const ServiceItem = [
    {
        title: "Drug Delivery",
        description: "Innovative solutions for identifying new drug candidates.",
        src: Drug,
        alt: "Drug",
    },
    {
        title: "Bio Consulting",
        description: "Expert advice and strategic guidance for your projects.",
        src: Consult,
        alt: "Consult",
    },
    {
        title: "Bio Computational Services",
        description: "Advanced computational methods for life sciences research.",
        src: BioService,
        alt: "Bio Service",
    },
    {
        title: "Biomedical services",
        description: "State-of-the-art medical lab for precise and reliable testing.",
        src: Biomedical,
        alt: "Biomedical",
    },
    {
        title: "Training Services and Workshop",
        description: "Range of in-house training sessions in various areas of sciences.",
        src: Training,
        alt: "Training",
    },
]
