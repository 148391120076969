import {NavLink} from "react-router-dom";
import {MenuType} from "../../utils/enums";

const BookTest = () => (
    <section className="section d-flex justify-content-center mx-4">
        <div className="bg-primary text-white rounded-3 py-5 px-4" style={{ maxWidth: "1038.58px", width: "100%" }}>
            <div className="d-flex flex-column text-center">
                <h3 className="mb-4">Book a test online now</h3>
                <p className="align-self-center mb-4" style={{ maxWidth: "850px", width: "100%" }}>
                    From here you'll be redirected to our locations page. Simply enter your preferred location,
                    test type, and then 'book appointment'. See below for further information on available tests.
                </p>
              <NavLink className="btn btn-white mx-auto"
                       to={`/${MenuType.CONSTRUCTION}`}>
                Click here to book online
              </NavLink>
            </div>
        </div>

    </section>
);

export default BookTest;
