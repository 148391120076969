import { Image } from "react-bootstrap";
import { SocialMedium } from "../../utils/mapper/footer";

const Socials = () => (
    <div className="d-inline-flex gap-2 gap-sm-3 my-3">
        {SocialMedium.map((social, index) =>
            <div key={index} className="bg-black rounded-circle p-2 d-flex align-items-center justify-content-center" style={{ width: "30px", height: "30px" }}>
                <Image src={social.src} alt={social.alt} />
            </div>
        )}
    </div>
);

export default Socials;
