import {NavLink} from "react-router-dom";
import {Container, Image} from "react-bootstrap";
import Link from "../UI/Link";
import Socials from "../UI/Socials";
import {AppInfo, LinkType} from "../../utils/enums";
import Logo from "../../assets/images/pngs/logo.png";
import {FooterLinks} from "../../utils/mapper/footer";

const Footer = () => (
  <footer>
    <Container>
      <div className="footer">
        <NavLink to="/"><Image src={Logo} alt="Logo" width={121} height={98}/></NavLink>
        <div className="footer-links">
          {FooterLinks.map((item, index) =>
            <div key={index} className={item.className}>
              <h6 className="fw-bold mb-3">{item.title}</h6>
              {(item.content && item.content.links) && item.content.links.map((link, linkIndex) =>
                <Link key={linkIndex} href={link.href} text={link.text} type={link.type} className="d-block mb-3"/>
              )}
            </div>
          )}
          <div className="order-4 align-self-center align-self-lg-start">
            <h6 className="fw-bold mb-3">Contact</h6>
            <Link href="2348035162818" text="+234 (0) 803 516 2818" type={LinkType.PHONE}
                  className="d-block mb-3"/>
            <Link href="zenebioscience@gmail.com" text="zenebioscience@gmail.com" type={LinkType.EMAIL}
                  className="d-block mb-3"/>

            <Socials/>

            <h6 className="fw-bold my-3">Address</h6>
            <p>34, Ofili street, Goshen comm. Aule <br/> Akure, Ondo state</p>
          </div>
        </div>
      </div>
    </Container>
    <small className="d-block text-center mt-3">
      Copyright &copy; {new Date().getFullYear()} <a href="www.zenebiosciences.com/" target="_blank"
                                                     rel="noreferrer">{AppInfo.APP_NAME}</a>. All right reserved.
    </small>
  </footer>
);

export default Footer;
