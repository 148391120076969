export const emailValidator = (email: string): boolean => {
  const pattern = /\S+@\S+\.\S+/, validEmail = email.toLowerCase().replace(/ /g, "");
  return pattern.test(validEmail);
};

export const nameValidator = (name: string): boolean => {
  const pattern = /^(?=.{2,50}$)[a-zA-Z]+([',\- ][a-zA-Z]+)*\d{0,2}$/;
  return pattern.test(name);
};


