import {NavLink} from "react-router-dom";
import {Container, Image} from "react-bootstrap";
import Header from "./header/Header";
import {MenuType} from "../../utils/enums";

type Props = {
  menu: MenuType,
  title: string,
  image: string,
  imageAlt: string,
  navText: string,
  description: string,
};

const ErrorLayout = ({menu, title, description, navText, image, imageAlt} : Props) => (
  <section className="main-body">
      <Header activeMenu={menu}/>
      <main className="page">
        <Container className="d-flex flex-column justify-content-center text-center text-primary"
                   style={{ maxHeight: "100vh", padding: "20px 10px 10px" }}>
          <div style={{ maxWidth: "530px"}} className="mx-auto mt-5 w-100">
            <Image alt={imageAlt} src={image} className="image-fluid" />
          </div>
          <h2 className="mt-5">{title}</h2>
          <p style={{ maxWidth: "800px" }} className="align-self-center mt-3">
            {description}
            {navText && <NavLink to="/" className="fw-semibold text-20 text-decoration-underline">{navText}</NavLink>}
          </p>
        </Container>
      </main>
  </section>
)

export default ErrorLayout;
