import { Fragment } from "react/jsx-runtime";
import { LinkType } from "../../utils/enums";
import { NavLink } from "react-router-dom";

type Props = {
    href: string,
    text: string,
    type: LinkType,
    className?: string,
}

const Link = ({ type, text, href, className } : Props) => (
    <Fragment>
        {type === LinkType.IN_APP
            ? <NavLink to={href} className={`${className ? className : ""}`}>{text}</NavLink>
            : type === LinkType.EMAIL
            ? <a href={`mailto:${href}`} target="_blank" rel="noreferrer" className={`${className ? className : ""}`}>{text}</a>
            : type === LinkType.PHONE
            ? <a href={`tel:${href}`} target="_blank" rel="noreferrer" className={`${className ? className : ""}`}>{text}</a>
            : <a href={href} target="_blank" rel="noreferrer" className={`${className ? className : ""}`}>{text}</a>
        }
    </Fragment>
);

export default Link;