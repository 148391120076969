import ErrorLayout from "../components/layouts/ErrorLayout";
import {MenuType} from "../utils/enums";
import ConstructionSVG from "../assets/images/svgs/construction.svg";

const UnderConstruction = ({menu}: {menu?: MenuType}) =>  (
  <ErrorLayout menu={menu ?? MenuType.ERROR}
               title="🔬 Experiment in Progress!"
               image={ConstructionSVG}
               imageAlt="Construction"
               navText="here."
               description={`This page is still in the lab, going through some vigorous testing.
                Once we get the right results, it’ll be ready to publish! Until then, 
                you can explore other areas of our site `}
  />
);

export default UnderConstruction;
