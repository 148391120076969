import ErrorLayout from "../components/layouts/ErrorLayout";
import {MenuType} from "../utils/enums";
import NotFound from "../assets/images/svgs/not-found.svg";

const Error404 = () => (
  <ErrorLayout menu={MenuType.ERROR}
               title="Error 404: We Lost This in the Lab!"
               image={NotFound}
               imageAlt="NOT-FOUND"
               navText="Go back to the lab"
               description={`It looks like the page you're trying to reach has escaped our experiment.
               We're searching for it under the microscope. `}
  />
)
export default Error404;
