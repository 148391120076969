import FelmatLogo from "../../assets/images/pngs/felmat.png";
import CelkhaLogo from "../../assets/images/pngs/celkha.png";
import OprlLogo from "../../assets/images/pngs/oprl.png";
import TeadyLogo from "../../assets/images/pngs/teady.png";
import InqaLogo from "../../assets/images/pngs/inqa-biotec.png";

export const BrandsList = {
  title: "Parent Company",
  brand: {
    src: FelmatLogo,
    alt: "Felma",
    width: 198,
    height: 52,
  },
  content: {
    title: "Our Partners",
    brand: [
      {
        src: CelkhaLogo,
        alt: "Celka",
        width: 205,
        height: 54,
      },
      {
        src: OprlLogo,
        alt: "OPRL",
        width: 80,
        height: 82,
      },
      {
        src: TeadyLogo,
        alt: "Teady",
        width: 80,
        height: 82,
      },
      {
        src: InqaLogo,
        alt: "Inqa Biotec",
        width: 105,
        height: 105,
      },
    ]
  }
}
