import {Col, Image, Row} from "react-bootstrap";
import {MenuType} from "../utils/enums";
import BookTest from "../components/UI/BookTest";
import Services from "../components/UI/Services";
import PageLayout from "../components/layouts/PageLayout";
import LabPersonnel from "../assets/images/jpegs/lab-woman.jpeg";
import {NavLink} from "react-router-dom";

const Home = () => (
  <PageLayout menu={MenuType.HOME}>
    <Row className="mb-5 d-flex justify-content-between">
      <Col md={6}>
        <Image src={LabPersonnel} alt="Lab Personnel" className="image-fluid"/>
      </Col>
      <Col md={6} className="d-flex flex-column mt-5 mt-xl-auto">
        <div className="align-self-center text-center text-lg-start mx-0 mx-lg-5 w-100" style={{maxWidth: "520px"}}>
          <h1 className="text-primary mb-4">
            Your Partner in Life Sciences Innovation
          </h1>
          <p>Leading the way in drug discovery, bio consulting,
            bio computational services, and more</p>
          <NavLink className="btn btn-primary my-4 mx-auto mx-lg-0"
                  to={`/${MenuType.CONSTRUCTION}`}>
            GET STARTED
          </NavLink>
        </div>
      </Col>
    </Row>
    <Services/>
    <BookTest/>
  </PageLayout>
);

export default Home;
