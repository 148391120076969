import { MenuType } from "../enums";

export const NavLinks = [
    {
        text: "Home",
        menuType: MenuType.HOME,
        href: `/`,
    },
    {
        text: "About us",
        menuType: MenuType.ABOUT_US,
        href: `/${MenuType.ABOUT_US}`,
    },
    {
        text: "Our Services",
        menuType: MenuType.CONSTRUCTION,
        href: `/${MenuType.CONSTRUCTION}`,
    },
    {
        text: "Contact us",
        menuType: MenuType.CONTACT_US,
        href: `/${MenuType.CONTACT_US}`,
    },
    {
        text: "Products",
        menuType: MenuType.PRODUCT,
        href: `/${MenuType.PRODUCT}`,
    },
];
