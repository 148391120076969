export enum AppInfo {
    APP_NAME = 'Zene Bioscience limited',
    APP_VERSION = 'v1',
}

export enum LinkType {
    EMAIL = "EMAIL",
    PHONE = "PHONE",
    TARGET = "TARGET",
    IN_APP = "IN_APP",
}

export enum MenuType {
    HOME = "home",
    ERROR = "error",
    PRODUCT = "products",
    ABOUT_US = "about-us",
    CONTACT_US = "contact-us",
    CONSTRUCTION = "under-construction",
}

export const ResponseType = {
    ERROR: 'ERROR',
    EXISTS: 'EXISTS',
    SUCCESS: 'SUCCESS',
}
