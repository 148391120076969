import {Root, createRoot} from 'react-dom/client';
import './assets/scss/style.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {NavContextProvider} from "./components/hooks/useMobileNav";

const AppRoot = () => (
  <NavContextProvider>
    <App/>
  </NavContextProvider>
);

const root: Root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppRoot/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
