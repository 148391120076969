import {ReactElement} from "react";
import Footer from "./Footer";
import Brands from "../UI/Brands";
import Header from "./header/Header";
import Newsletter from "../UI/Newsletter";
import {MenuType} from "../../utils/enums";

type Props = {
  menu: MenuType,
  children: ReactElement | ReactElement[]
}
const PageLayout = ({children, menu}: Props) => (
  <section className="main-body">
    <main>
      <Header activeMenu={menu}/>
      <div className="page">{children}</div>
    </main>
    <Brands/>
    <Newsletter/>
    <Footer/>
  </section>
);

export default PageLayout;
