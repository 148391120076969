import { ReactElement, createContext, useContext, useState } from "react";

type NavContextType = {
  navMenu: boolean,
  toggleNavMenu: (value?: boolean) => void,
}

export const NavContext = createContext<NavContextType>({
  navMenu: false,
  toggleNavMenu: (value?: boolean) => {},
});

export const useMobileNav = () => useContext(NavContext);

export const NavContextProvider = ({ children }: { children: ReactElement }) => {
  const [navMenu, setNavMenu] = useState(false);

  const toggleNavMenu = (value?: boolean) => {
    if (value) setNavMenu(value);
    else setNavMenu(prev => !prev)
  }

  const value = { navMenu, toggleNavMenu };

  return (
    <NavContext.Provider value={value}>
      {children}
    </NavContext.Provider>
  );
};
