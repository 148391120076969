import {NavLink} from "react-router-dom";
import {Image} from "react-bootstrap";
import Logo from "../../../assets/images/pngs/logo.png";
import MenuSVG from "../../../assets/images/svgs/menu.svg";
import SearchSVG from "../../../assets/images/svgs/search.svg";

import MobileHeader from "./MobileHeader";
import {MenuType} from "../../../utils/enums";
import {useMobileNav} from "../../hooks/useMobileNav";
import {NavLinks} from "../../../utils/mapper/header";

const Header = ({activeMenu}: {activeMenu: MenuType}) => {
  const navMode = useMobileNav();

  return (
    <header className="fixed-top">
      <nav>
					<NavLink to="/" className="my-auto">
						<Image src={Logo} alt="Logo" width={75} height={55}/>
					</NavLink>

        <ul className="nav">
          {NavLinks.map((item, index) =>
            <li key={index} className="nav-link">
              <NavLink to={item.href} className={activeMenu === item.menuType ? "active" : ""}>{item.text}</NavLink>
            </li>
          )}
        </ul>
        <div className="nav-action">
          <button className="btn btn-link text-color" style={{fontSize: "15px"}}>
            Search <Image src={SearchSVG} alt="search" width={20} height={20}/>
          </button>
        </div>

        <Image className="menu" src={MenuSVG} alt="menu" width={24} height={24}
               onClick={() => navMode.toggleNavMenu(true)}/>
      </nav>
      <MobileHeader activeMenu={activeMenu}/>
    </header>
  );
}
export default Header;
