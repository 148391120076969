import PageLayout from "../components/layouts/PageLayout";
import {Container, Image} from "react-bootstrap";
import Lab from "../assets/images/jpegs/about-us.jpeg";
import {MenuType} from "../utils/enums";

const AboutUs = () =>  (
  <PageLayout menu={MenuType.ABOUT_US}>
    <Container className="section">
      <div className="section">
        <h3 className="text-primary">About Us</h3>
      </div>
      <div className="section">
        <div className="d-flex flex-column flex-lg-row gap-5">
            <Image src={Lab} alt="About Us" width={384} className="mx-auto mx-lg-0"/>
          <div>
            <div>
              <h5 className="text-primary mb-4">About us</h5>
              <p>
                Zene bioscience is a one stop solution provider for life Sciences
                services focused on Drug discovery, bio Consulting, bio-computational
                Services, bio Services, lab space renting, and research in life sciences
              </p>
            </div>
            <div className="mt-5">
              <h5 className="text-primary mb-4">Mission statement</h5>
              <p>
                At Zene Bioscience, our mission is to advance the frontiers of life sciences by providing innovative, high-quality solutions in drug discovery, bio consulting, computational biology, and laboratory services.
              </p>
              <p>
                At Zene Bioscience, our mission is to advance the frontiers of life sciences by providing innovative, high-quality solutions in drug discovery, bio consulting, computational biology, and laboratory services.

                We are committed to fostering scientific breakthroughs that improve lives, supporting our clients with expert guidance, and offering state-of-the-art facilities to drive impactful research. Through collaboration and excellence, we aim to be a trusted partner in the global pursuit of health and scientific innovation.
              </p>
            </div>

          </div>
        </div>
      </div>
    </Container>
  </PageLayout>
);


export default AboutUs;
