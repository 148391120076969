import {LinkType, MenuType} from "../enums";

import Twitter from "../../assets/images/svgs/twitter.svg";
import LinkedIn from "../../assets/images/svgs/linkedIn.svg";
import Facebook from "../../assets/images/svgs/facebook.svg";
import Instagram from "../../assets/images/svgs/instagram.svg";

export const FooterLinks = [
  {
    title: "Company",
    className: "order-2 order-md-0",
    content: {
      links: [
        {
          text: "About",
          href: `/${MenuType.ABOUT_US}`,
          type: LinkType.IN_APP
        },
        {
          text: "Press",
          href: `/${MenuType.CONSTRUCTION}`,
          type: LinkType.IN_APP
        },
        {
          text: "Career",
          href: `/${MenuType.CONSTRUCTION}`,
          type: LinkType.IN_APP
        },
        {
          text: "Privacy policy",
          href: `/${MenuType.CONSTRUCTION}`,
          type: LinkType.IN_APP
        },
        {
          text: "Terms of service",
          href: `/${MenuType.CONSTRUCTION}`,
          type: LinkType.IN_APP
        },
      ]
    }
  },
  {
    title: "Products",
    className: "order-1",
    content: {
      links: [
        {
          text: "Business",
          href: `/${MenuType.CONSTRUCTION}`,
          type: LinkType.IN_APP
        }
      ]
    }
  },
  {
    title: "Learn more",
    className: "order-0 order-md-3",
    content: {
      links: [
        {
          text: "Blog",
          href: `/${MenuType.CONSTRUCTION}`,
          type: LinkType.IN_APP
        },
        {
          text: "FAQ",
          href: `/${MenuType.CONSTRUCTION}`,
          type: LinkType.IN_APP
        },
        {
          text: "Resources",
          href: `/${MenuType.CONSTRUCTION}`,
          type: LinkType.IN_APP
        },
      ]
    }
  }
];

export const SocialMedium = [
  {
    src: Instagram,
    alt: "Instagram"
  },
  {
    src: Twitter,
    alt: "Twitter"
  },
  {
    src: Facebook,
    alt: "Facebook"
  },
  {
    src: LinkedIn,
    alt: "LinkedIn"
  },
]
