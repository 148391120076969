import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MenuType } from "./utils/enums";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Error404 from "./pages/Error404";
import Products from "./pages/Products";
import ContactUs from "./pages/ContactUs";
import UnderConstruction from "./pages/UnderConstruction";
import UseScrollToTop from "./components/hooks/UseScrollToTop";


const App = () => (
  <Router>
    <UseScrollToTop/>
    <Routes>
      {['/', MenuType.HOME].map(path => <Route path={path} key={path} element={<Home />} />)}
      <Route path={MenuType.ABOUT_US} element={<AboutUs />} />
      <Route path={MenuType.CONTACT_US} element={<ContactUs />} />
      <Route path={MenuType.PRODUCT} element={<Products />} />
      <Route path={MenuType.CONSTRUCTION} element={<UnderConstruction />} />
       <Route path="*" element={<Error404 />} />
    </Routes>
  </Router>
);

export default App;
