import {Button, Image, Offcanvas} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {MenuType} from "../../../utils/enums";
import {useMobileNav} from "../../hooks/useMobileNav";
import {NavLinks} from "../../../utils/mapper/header";
import Logo from "../../../assets/images/pngs/logo.png";
import closeSVG from "../../../assets/images/svgs/close.svg";


const MobileHeader = ({activeMenu}: {activeMenu: MenuType}) => {
  const navMode = useMobileNav();
  const handleClose = () => navMode.toggleNavMenu(false);


  return (
    <div className="d-lg-none">
      <Offcanvas show={navMode.navMenu} onHide={handleClose} placement="start" backdrop="static" responsive="lg"
                 className="mobile-header">
        <Offcanvas.Header className="d-flex justify-content-between">
          <Offcanvas.Title>
            <NavLink to="/" className="my-auto">
              <Image src={Logo} alt="Logo" width={75} height={55}  />
            </NavLink>
          </Offcanvas.Title>
          <Button type="button" variant="link" className="px-0" onClick={handleClose}>
            <Image src={closeSVG} alt="close" width={24} height={24} />
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul>
            {NavLinks.map((item, index) =>
              <li key={index} className="nav-link">
                <NavLink to={item.href} onClick={() => handleClose()}
                         className={activeMenu === item.menuType ? "active" : ""}>
                  {item.text}
                </NavLink>
              </li>
            )}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default MobileHeader;
