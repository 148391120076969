import {useNavigate} from "react-router-dom";
import {Breadcrumb, Container, Image} from "react-bootstrap";
import {MenuType} from "../utils/enums";
import Lab from "../assets/images/jpegs/contact-us.jpeg";
import PageLayout from "../components/layouts/PageLayout";

const ContactUs = () => {
  const navigate = useNavigate();
  return (
    <PageLayout menu={MenuType.CONTACT_US}>
      <Container className="section">
        <div className="section">
          <h3 className="text-primary">Contact Us</h3>
        </div>
        <div className="section">
          <Breadcrumb className="mt-3 mb-4">
            <Breadcrumb.Item active={false} onClick={() => navigate(`/`)}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active={false} onClick={() => navigate(`/${MenuType.ABOUT_US}`)}>
              About us
            </Breadcrumb.Item>
            <Breadcrumb.Item active={true}>
              Contact us
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex flex-column flex-lg-row row-gap-4 column-gap-5">
            <Image src={Lab} alt="About Us" width={384} height={530} className="mx-auto mx-lg-0"/>
            <div>
              <div>
                <h5 className="text-primary mb-4">General Enquiries</h5>
                <p>
                  <span className="fw-bold">Address:</span>
                  &nbsp;Block E, Sunshine Estate, Off Oda Road, Akure, Ondo State, Nigeria.
                </p>
                <p>
                  <span className="fw-bold">Postal address:</span>
                  &nbsp;34, Ofili street, Goshen comm. Aule Akure, Ondo state
                </p>
                <p><span className="fw-bold">Phone:</span> +234 (0) 803 516 2818, +234 (0) 903 733 7510 – all hours</p>
                <p><span className="fw-bold">Email:</span> zenebioscience@gmail.com</p>
              </div>
              <div className="mt-5">
                <h5 className="text-primary mb-4">Opening hours</h5>
                <p><span className="fw-bold">Mon-Fri:</span> 09:00 - 17:00</p>
                <p><span className="fw-bold">Public Holidays:</span> Closed</p>
                <p><span className="fw-bold">Weekends:</span> Closed</p>
              </div>

            </div>
          </div>
        </div>
      </Container>
    </PageLayout>
  );
}

export default ContactUs;
