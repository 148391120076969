import {Container, Image} from "react-bootstrap";
import {BrandsList} from "../../utils/mapper/brands";

const Brands = () => (
  <Container fluid className="brands">
    <div className="d-flex flex-column justify-content-center text-center">
      <h4 className="text-primary mb-4">{BrandsList.title}</h4>
      <Image className="align-self-center mb-3" src={BrandsList.brand.src} alt={BrandsList.brand.alt}
             width={BrandsList.brand.width} height={BrandsList.brand.height}/>
      <div className="mt-5">
        <h5 className="text-primary my-4">{BrandsList.content.title}</h5>
        <div className="d-flex flex-wrap justify-content-center align-items-center gap-5">
          {BrandsList.content.brand.map((brand, index) =>
            <Image key={index} src={brand.src} alt={brand.alt} width={brand.width} height={brand.height}/>
          )}
        </div>
      </div>
    </div>
  </Container>
)

export default Brands;
