import { Container, Card, Row, Col, Image } from "react-bootstrap";
import { ServiceItem } from "../../utils/mapper/services";

const Services = () => (
    <Container className="section mx-auto">
        <Card>
            <Card.Body className="p-2 p-lg-5">
                <div className="text-primary text-center my-5">
                    <p>OUR SERVICES</p>
                    <h4>What we offer</h4>
                </div>
                <Row className="mx-auto" style={{maxWidth: "1000px", width: "100%"}}>
                    {ServiceItem.map((item, index) =>
                        <Col lg={6} key={index}>
                            <div className="d-flex flex-column flex-sm-row gap-3 gap-md-4 border border-light rounded-3 p-3 my-3 raise-transition">
                                <div className="align-self-center">
                                    <Image src={item.src} alt={item.alt} width={134.84} height={153.35} className="mx-auto"/>
                                </div>
                                <div className="text-center text-sm-start my-auto me-5 w-100">
                                    <h5 className="text-primary mb-2 mb-md-3">{item.title}</h5>
                                    <p className="smaller">{item.description}</p>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </Card.Body>
        </Card>
    </Container>
);

export default Services;
